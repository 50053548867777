import { Component, ViewContainerRef } from '@angular/core';
import { AuthService } from './shared/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(private auth: AuthService) {
        setInterval(() => {
            this.auth.refresh().subscribe()
        }, 900000)

       // this.scheduleRefresh()
     }

    //  scheduleRefresh(){
    //     setTimeout(() => {
    //         this.auth.refresh().subscribe(()=>{
    //             this.scheduleRefresh()
    //         })
    //     }, 12000)
    //  }
}

// TODO order view issue
// TODO order 3 days feature
// TODO cancelation API slow
// TODO offering model multi-select

