import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/users`);
  }

  getUser() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/users/me`);
  }

  createVendorUser(user) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/users/createVendorUser`, user);
  }

  getVendorUsers(vendorId) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/users/bv/` + vendorId);
  }

  blockUser(id) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/users/` + id + '/block', {});
  }

  unblockUser(id) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/users/` + id + '/unblock', {});
  }
}
