import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { from, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  /**
   * 
   * @param routee 
   * @param state 
   * 
   * token expired? lets try to refresh it, still expired?
   */
  canActivate(routee: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    console.log('can activate')
    return from(this.authService.isAuthenticatedAsync()).pipe(map(e => {
      console.log('yes', e)

      if(e === true){
        return true;
      }else{
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        return false;
      }

      }))

    //return false;
    // const isAuthenticated = this.authService.isAuthenticatedAsync().then(() => {
    //   return true;
    // }).catch(() => {
    //   this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    //   return false;
    // })

    // if (isAuthenticated){
    //   return true;
    // }else{
    //   this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    //   return false;
    // }
  }
}
