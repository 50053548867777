import { Component, OnInit } from '@angular/core';
import { ROUTES, ROUTES2 } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/userService/user.service';
import { OrdersService } from 'app/services/orders/orders.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public user: any;

    constructor(private orderService: OrdersService, private userService: UserService, private router: Router,
        private route: ActivatedRoute, public translate: TranslateService) {
        this.user = {}
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');


        this.userService.getUser().subscribe((user) => {
            this.user = user;
            if (user.role == 'accounting') {
                this.menuItems = ROUTES2.filter(menuItem => menuItem);
            } else {
                this.menuItems = ROUTES.filter(menuItem => menuItem);
            }

            this.orderService.getUndecidedCancelations().subscribe((orders) => {
                this.menuItems.find(item => item.title == 'Orders').badge = orders.length
            })

            setInterval(() => {
                this.orderService.getUndecidedCancelations().subscribe((orders) => {
                    this.menuItems.find(item => item.title == 'Orders').badge = orders.length
                })
            }, 10000)


        }, (err) => {
            console.log(err)
        })
    }

    //NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf('forms/ngx') !== -1) {
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
        }
    }
}
