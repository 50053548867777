import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient, private globals: Globals) {

  }

  getAll() {
    console.log('------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,');
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders`);
  }
  getAllWithout() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/admin`);
  }
  getDataWithPagination(data, page) {
    let queryParam = '?';
    queryParam = (queryParam + 'orderId=') + (data.orderId ? data.orderId : '');
    queryParam = (queryParam + '&vendorId=') + (data.vendorId ? data.vendorId : '');
    queryParam = (queryParam + '&eventDate=') + (data.eventDate ? data.eventDate : '');
    queryParam = (queryParam + '&orderStatus=') + (data.orderStatus ? data.orderStatus : '');
    queryParam = queryParam + '&page=' + page;
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/admin-with-page${queryParam}`);
  }

  getUndecidedCancelations() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/cancelations`);
  }

  reviewOrder(orderDbId, isApproved) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/orders/${orderDbId}/cancelations/review`, { result: isApproved });
  }


  get(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/${id}`);
  }

  cancel(id: string) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/orders/${id}/cancel`, {});
  }
  getAllcash_register() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/cash_register`);
  }


  getcash_register(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/cash_register/${id}`);
  }


  cancelcash_register(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/cash_register/${id}`);
  }
  sendmail(email) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/admin/sendvendorinvoice`, email);
  }
  addcash_register(cash_register: any) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/cash_register`, cash_register);
  }
  //   getOfferings(id: string) {
  //     return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}/offerings`);
  //   }

  //   add(vendor: any) {
  //     return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
  //   }

  //   update(vendor: any) {
  //     return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
  //   }

  //   delete(id: string) {
  //     return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  //   }
  getOrderByDate(vendor, from, to) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/order-report-admin/${vendor}/${from}/${to}`);
  }
}
